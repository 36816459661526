import React, { useEffect, useState } from "react";
import InputMask from 'react-input-mask';
import { Link } from "react-router-dom";
import api from "../../../api/api";
import arrow from "../../../assets/Arrow 19.png";
import quest from "../../../assets/Outline.png";
import CloseIcon from '../../../assets/icons-menu-close.svg';
import DropdownForForm from "../../../components/DropDownForFormComponent";
import ErrorMessage from "../../../components/ErrorMessage";
import Loading from "../../../components/Loading";
import PopupCustomComponent from '../../../components/PopupCustomComponent';
import Button from '../../../components/button/Button';
import Modal from '../../../components/modal/Modal';
import { error, success } from "../../../components/notification";
import styles from "./SettingsComponent.module.css";
import TariffsBlock from './TariffsBlock';

const taxationOptions = [
    { name: 'общая СН', id: 'osn' },
    { name: 'упрощенная СН (доходы)', id: 'usn_income' },
    { name: 'упрощенная СН (доходы минус расходы)', id: 'usn_income_outcome' },
    { name: 'единый налог на временный доход', id: 'envd' },
    { name: 'единый сельскохозяйственный налог', id: 'esn' },
    { name: 'патентная СН', id: 'patent' }
];

const countryCodes = [
    { name: 'Российская Федерация', id: 'RUS' },
    { name: 'Республика Казахстан', id: 'KAZ' },
    { name: 'Республика Беларусь', id: 'BLR' },
    { name: 'Республика Армения', id: 'ARM' },
    { name: 'Азербайджанская Республика', id: 'AZE' },
    { name: 'Кыргызская Республика', id: 'KGZ' },
    { name: 'Республика Таджикистан', id: 'TJK' },
    { name: 'Республика Узбекистан', id: 'UZB' },
];

const addressTypes = [
    { name: 'юридический', id: 'legal' },
    { name: 'фактический', id: 'actual' },
];

function SettingsComponent() {

    const scrollToElement = (element) => {
        const paymentElement = document.getElementById(element);
        paymentElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    };

    const [showTooltip, setShowTooltip] = useState(false);
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [isEmailNotifications, setIsEmailNotifications] = useState(false);
    const [isTelegramNotifications, setIsTelegramNotifications] = useState(false);

    const [isError1, setIsError1] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorText1, setErrorText1] = useState('');

    const [isErrorNotification, setIsErrorNotification] = useState(false);
    const [errorNotificationText, setErrorNotificationText] = useState('');

    const [currentTariff, setCurrentTariff] = useState(0);

    const [currentEmail, setCurrentEmail] = useState('');

    /*PAYMENT*/
    const [isPaymentSettingVisible, setPaymentSettingsVisible] = useState(false);
    const [isPaymentApproveModalVisible, setPaymentApproveModalVisible] = useState(false);
    const [isPaymentSettingsApproved, setPaymentSettingsApproved] = useState(false);
    const [isApprovePending, setApprovePending] = useState(false);
    const [formErrors, setFormErrors] = useState({});

    const [ogrn, setOgrn] = useState('');
    const [taxation, setTaxation] = useState('');

    const [isPaymentConnected, setIsPaymentConnected] = useState(false);
    const [isPaymentDataSent, setIsPaymentDataSent] = useState(false);

    const handleRequestApprove = (e) => {
        if (!ogrn) {
            return;
        } else {
            setApprovePending(true)
            api.checkOgrn(ogrn).then((response) => {
                setPaymentSettingsApproved(response.data.status);
                if (response.data.status) {
                    setPaymentData({ ...paymentData, ...response.data.info });
                    setPaymentApproveModalVisible(false);
                }
                setApprovePending(false);
            }).catch(() => {
                setApprovePending(false);
                setPaymentSettingsVisible(false);
                setPaymentApproveModalVisible(false);
                setOgrn('');
                error('Не удалось проверить ОГРН!');
            });
        }
    };

    const [paymentData, setPaymentData] = useState({
        legal_address: {
            city: '',
            country: '',
            street: '',
            type: 'legal',
            zip: ''
        },
        bankAccount: {
            account: '',
            bankName: '',
            bik: '',
        },
        ceo: {
            birthDate: '',
            country: '',
            firstName: '',
            lastName: '',
            middleName: '',
            phone: ''
        },
        billingDescriptor: '',
        email: '',
        fullName: '',
        inn: '',
        kpp: '',
        name: '',
        ogrn: 0,
        siteUrl: ''
    });

    // Новый метод
    const updatePaymentState = (fieldName, value) => {
        setPaymentData(prevState => {
            const newState = { ...prevState };
            const fieldNames = fieldName.split('.');
            const lastFieldName = fieldNames.pop();
            const fieldToUpdate = fieldNames.reduce((acc, curr) => acc[curr], newState);
            fieldToUpdate[lastFieldName] = value;
            return newState;
        });
    };

    const handlePaymentDataInputChange = (event) => {
        const { name, value } = event.target;
        if (name === 'billingDescriptor') {
            if (value.length > 14) {
                setFormErrors(prevState => ({ ...prevState, billingDescriptor: "Не более 14 символов" }));
                return;
            }

            if (!/^[a-zA-Z\s]*$/.test(value)) {
                setFormErrors(prevState => ({
                    ...prevState,
                    billingDescriptor: "Используйте только латинские символы"
                }));
                return;
            }
        }

        updatePaymentState(name, value);

        const fieldNames = name.split('.');

        if (fieldNames.length === 1) {
            setFormErrors(prevErrors => {
                const newErrors = { ...prevErrors };
                delete newErrors[name];
                return newErrors;
            });
        } else if (fieldNames.length === 2) {
            setFormErrors(prevErrors => {
                const newErrors = { ...prevErrors };
                const fullFieldName = `${fieldNames[0]}_${fieldNames[1]}`;
                delete newErrors[fullFieldName];
                return newErrors;
            });
        }
    }

    // const [paymentRequestPending, setPaymentRequestPending] = useState(false);

    const handleTaxationChange = (value) => {
        setTaxation(value);
    }

    const handlePaymentSubmit = () => {
        const errors = {};

        paymentData.ogrn = Number(ogrn);

        const validateField = (fieldName, fieldValue, errorMessage) => {
            if (!fieldValue) {
                errors[fieldName] = errorMessage;
            }
        };

        for (const section in paymentData) {
            if (Object.hasOwnProperty.call(paymentData, section)) {
                const fields = paymentData[section];
                if (typeof fields === 'object') {
                    for (const field in fields) {
                        if (Object.hasOwnProperty.call(fields, field)) {
                            const fieldName = `${section}_${field}`;
                            const fieldValue = fields[field];
                            validateField(fieldName, fieldValue, 'Поле обязательно для заполнения');
                        }
                    }
                } else {
                    validateField(section, fields, 'Поле обязательно для заполнения');
                }
            }
        }

        setFormErrors(errors);

        if (Object.keys(errors).length > 0) {
            return;
        }
        setIsLoading(true);

        api.setTaxation(taxation).then((response) => {
            const splittedData = paymentData.ceo.birthDate.split('-');

            function convertPhoneNumberToNumber(phoneNumberString) {
                const numericString = phoneNumberString.replace(/\D/g, '');
                return '+' + numericString;
            }

            // Payment JSON
            const paymentDataToSend = {
                billing_descriptor: paymentData.billingDescriptor,
                store_id: localStorage.getItem('current_store_id', ''),
                full_name: paymentData.fullName,
                name: paymentData.name,
                ogrn: paymentData.ogrn,
                inn: paymentData.inn,
                kpp: paymentData.kpp,
                addresses: [
                    paymentData.legal_address
                ],
                email: paymentData.email,
                ceo: {
                    phone: convertPhoneNumberToNumber(paymentData.ceo.phone),
                    first_name: paymentData.ceo.firstName,
                    last_name: paymentData.ceo.lastName,
                    middle_name: paymentData.ceo.middleName,
                    birth_date: `${splittedData[2]}-${splittedData[1]}-${splittedData[0]}`,
                    country: paymentData.ceo.country
                },
                site_url: paymentData.siteUrl,
                bank_account: {
                    account: paymentData.bankAccount.account.replace(/\s/g, ''),
                    bank_name: paymentData.bankAccount.bankName,
                    bik: paymentData.bankAccount.bik,
                    details: `Оплата товаров и услуг`,
                }
            };

            api.registerShop(paymentDataToSend).then(r => {
                setIsPaymentDataSent(true);
                success('Данные успешно отправлены!');
                setIsLoading(false);
                setIsPaymentConnected(true);
                setPaymentSettingsVisible(false);
            }).catch(() => {
                error('Не удалось зарегистрировать магазин!');
                setIsLoading(false);
            });
        });
    }

    /*END PAYMENT*/

    const handleMouseEnter = () => {
        setShowTooltip(true);
    };

    const handleMouseLeave = () => {
        setShowTooltip(false);
    };

    const [userData, setUserData] = useState({
        username: '',
        first_name: '',
        last_name: '',
        email: '',
        tg_username: '',
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name === 'tg_username') {
            setIsErrorNotification(false);
        }
        setUserData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    useEffect(() => {
        api.getUserData().then((response) => {
            if (response.data) {
                setUserData({
                    username: response.data.profile.username,
                    first_name: response.data.profile.first_name,
                    last_name: response.data.profile.last_name,
                    email: response.data.profile.email,
                    tg_username: response.data.profile.tg_username,
                    is_company: response.data.profile.is_company,
                })
                setCurrentEmail(response.data.profile.email);
                updatePaymentState('email', response.data.profile.email);
                setIsEmailNotifications(response.data.profile.is_email_notifications);
                setIsTelegramNotifications(response.data.profile.is_telegram_notifications);
                setCurrentTariff(response.data.tariff);

                if (localStorage.getItem('current_store_id')) {
                    api.getShopID(localStorage.getItem('current_store_id')).then((r) => {
                        if (r.status === 200) {
                            setIsPaymentConnected(true);
                        }
                    });
                }
            }
        }).catch((error) => {
            error('Не удалось получить данные пользователя!');
        });

        document.body.classList.add(styles.bodyRuleDisappear);

        return () => {
            document.body.classList.remove(styles.bodyRuleDisappear);
        };
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (newPassword === '' | confirmPassword === '' | currentPassword === '') {
            error('Чтобы изменить пароль заполните все поля');
        }
        else if (newPassword === confirmPassword) {
            const newData = {
                'current_password': currentPassword,
                'new_password': newPassword
            }
            api.updateUserPassword(newData).then((response) => {
                if (response.response) {
                    if (response.response.status === 400) {
                        error('Проверьте введенные данные и попробуйте снова');
                    }
                } else if (response.status) {
                    if (response.status === 200) {
                        success('Пароль успешно обновлен!');
                    }
                }
            })
        } else {
            error('Пароли не совпадают');
        }
    };

    const handleChangeUserData = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const newData = userData;
        newData.tg_username = newData.tg_username.replace('@', '');

        if (currentEmail !== userData.email) {
            api.emailChange({ "new_email": userData.email })
                .catch(e => { console.log(e) })
            newData.email = currentEmail;
        }

        api.updateUserData(newData).then((response) => {
            if (response.response) {
                if (response.response.status === 400) {
                    error('Проверьте введенные данные и попробуйте снова');
                }
            } else if (response.status) {
                if (response.status === 200) {
                    success('Данные обновлены!');
                }
            }
        })
    };

    useEffect(() => {
        setIsError1(false);
        setErrorText1('');
    }, [userData]);

    const changeEmailFlag = () => {
        const new_flag = !isEmailNotifications;
        setIsEmailNotifications((prevState) => new_flag);
        api.updateUserEmailFlag({ 'is_email_notifications': new_flag })
            .catch((e) => { });
    };

    const changeTGFlag = () => {
        if (userData.tg_username === '' | userData.tg_username === undefined) {
            setErrorNotificationText('Чтобы подключить уведомления в телеграмм заполните поле "Username в Telegram"');
            setIsErrorNotification(true);
        } else {
            const new_flag = !isTelegramNotifications;
            setIsTelegramNotifications((prevState) => new_flag);
            api.updateTGUserFlag({ 'is_telegram_notifications': new_flag })
                .catch((e) => {
                    error('Произошла ошибка, не удалось включить уведомления!');
                });
        }
    };

    const handleDeleteAccount = () => {
        api.deleteUser()
            .then((response) => {
                if (response.status) {
                    if (response.status === 204) {
                        localStorage.clear()
                        window.location.reload()
                    } else {
                        error('Что-то пошло не так...');
                    }
                }
            })
            .catch((error) => { console.log(error) })
    };

    return (
        <div className={styles.megaContainer}>
            <div className={styles.container}>
                <div className={styles.menuOptions}>
                    <span onClick={() => { scrollToElement('profile') }}>Настройки профиля</span>
                    <span onClick={() => { scrollToElement('payment') }}>Настройки оплаты</span>
                    <span onClick={() => { scrollToElement('subscription') }}>Управление подпиской</span>
                </div>
                <h1 className={styles.title}>ваш профиль и настройки</h1>
                <div id="profile" className={styles.profileContainer}>
                    <div className={styles.profileBlock}>
                        <h4 className={styles.profileBlockTitle}>Данные профиля</h4>
                        <div className={styles.profileBlockGroup}>
                            <span>Имя пользователя:</span>
                            <input name='username' value={userData.username} disabled type="text" />
                        </div>
                        <div className={styles.profileBlockGroup}>
                            <span>Имя:</span>
                            <input name='first_name' value={userData.first_name} onChange={handleInputChange} type="text" />
                        </div>
                        <div className={styles.profileBlockGroup}>
                            <span>Фамилия:</span>
                            <input name='last_name' value={userData.last_name} onChange={handleInputChange} type="text" />
                        </div>
                        <div className={styles.profileBlockGroup}>
                            <span>Email: <img src={quest} alt="" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={{ position: 'relative', display: 'inline-block', cursor: "pointer" }} /></span>
                            {showTooltip && (
                                <div className={styles.emailInfoNotification}>
                                    Будет использоваться для отправки уведомлений
                                </div>
                            )}
                            <input name='email' value={userData.email} onChange={handleInputChange} type="text" />
                        </div>
                        <div className={styles.profileBlockGroup}>
                            <span>Username в Telegram</span>
                            <input name='tg_username' value={userData.tg_username} onChange={handleInputChange} type="text" />
                        </div>
                        {currentEmail !== userData.email ?
                            (<PopupCustomComponent triggerEl={<button type="button" className={`${styles.profileBtn} ${styles.profileBtnActive}`} onClick={(e) => handleChangeUserData(e)}>Сохранить изменения</button>} title={"Необходимо подтвердить новый e-mail."} text={"Вам на почту было отправлено письмо с ссылкой для подтверждения нового e-mail. Пожалуйста, перейдите в почту и действуйте согласно инструкции в письме."} handler={handleChangeUserData}>

                            </PopupCustomComponent>) : <button type="button" className={`${styles.profileBtn} ${styles.profileBtnActive}`} onClick={(e) => handleChangeUserData(e)}>Сохранить изменения</button>
                        }
                        <div>
                            {isError1 ? (<ErrorMessage message={errorText1} />) : (null)}
                        </div>
                    </div>
                    <div className={styles.profileBlock}>
                        <h4 className={styles.profileBlockTitle}>Изменить пароль</h4>
                        <div className={styles.profileBlockGroup}>
                            <span>Текущий пароль:</span>
                            <input type="text" onChange={(e) => setCurrentPassword(e.target.value)} />
                        </div>
                        <div className={styles.profileBlockGroup}>
                            <span>Новый пароль:</span>
                            <input type="text" onChange={(e) => setNewPassword(e.target.value)} />
                        </div>
                        <div className={styles.profileBlockGroup}>
                            <span>Подтвердить новый пароль:</span>
                            <input type="text" onChange={(e) => setConfirmPassword(e.target.value)} />
                        </div>
                        <button className={`${styles.profileBtn} ${styles.profileBtnActive}`} onClick={handleSubmit}>Изменить пароль</button>
                        {/* <div>
                            {isError ? (<ErrorMessage message={errorText} />) : (null)}
                        </div> */}
                    </div>
                    <div className={styles.profileBlock}>
                        <h4 className={styles.profileBlockTitle}>Настройка уведомлений</h4>
                        <p className={styles.toolTipText}>Здесь вы можете включить или выключить <span>уведомления о новых заказах</span> в вашем магазине</p>
                        <div className={styles.profileCheckBox}>
                            <span>Уведомления по e-mail</span>
                            <div className={styles.check}>
                                Выкл.<input id="remember" className={styles.authorizationFormRemember} type="checkbox" checked={isEmailNotifications} onChange={changeEmailFlag}></input>Вкл.
                            </div>
                        </div>
                        <div className={styles.profileCheckBox}>
                            <span>Уведомления по telegram</span>
                            <PopupCustomComponent triggerEl={<div className={styles.check}>
                                Выкл.<input id="remember" className={styles.authorizationFormRemember} type="checkbox" checked={isTelegramNotifications}></input>Вкл.
                            </div>} title={"Перед тем как поменять статус подтвердите изменения"} text={""} handler={(e) => { handleChangeUserData(e); changeTGFlag(); }}>
                            </PopupCustomComponent>

                            {isTelegramNotifications ? <>
                                <span>Перейдите в telegram бота, чтобы получать уведомления в telegram</span>
                                <a href="https://t.me/botique_order_notifier_bot" target="_blank" className={styles.goBtn} rel="noreferrer">Перейти <img src={arrow} alt="" /> </a>
                            </> : null}
                            {isErrorNotification ? (<ErrorMessage message={errorNotificationText} />) : (null)}
                        </div>
                    </div>
                </div>

                <div id="payment" className={styles.paymentContainer}>
                    <div className={styles.paymentInfo}>
                        <h1 className={styles.PayTitle}>Данные для приема онлайн платежей</h1>
                        {localStorage.getItem('current_store_id') === null ? (
                            <div className={styles.paymentForbidden}><strong>Для подключения онлайн оплаты вам необходимо создать магазин. По всем вопросам пишите нам в <Link to='/support'>поддержку</Link>.</strong></div>
                        ) : isPaymentConnected ? (
                            <div className={styles.paymentForbidden}><strong>Онлайн-оплата подключена. По всем вопросам пишите нам в <Link to='/support'>поддержку</Link>.</strong></div>
                        ) : userData.is_company ? <>
                            <span>Хотите ли вы подключить прием онлайн платежей в Вашем магазине?</span>
                            <span style={{ display: "flex", flexWrap: "wrap" }}>Не нужна онлайн оплата
                                <input id="remember"
                                    className={styles.authorizationFormRemember}
                                    type="checkbox"
                                    checked={userData.is_company ? isPaymentSettingVisible : false}
                                    onChange={userData.is_company ? ({ target: { checked } }) => {
                                        setPaymentApproveModalVisible(checked);
                                        setPaymentSettingsVisible(checked);
                                    } : null}
                                    disabled={!userData.is_company}
                                />
                                Подключить прием онлайн платежей
                            </span>
                        </> :
                            <div className={styles.paymentForbiddenContainer}>
                                <div className={styles.paymentForbidden}><span>Подключение онлайн-оплаты недоступно для физических лиц</span></div>
                                <div className={styles.tinText}>Но вы можете <a style={{ color: "#007bff" }} rel="noopener noreferrer" href="https://www.tbank.ru/business/registration-ip/form?utm_medium=ptr.act&utm_campaign=sme.partners&partnerId=5-3DN0LZH7F&agentId=5-38MDZDGKW&agentSsoId=2fc433e5-1f12-4f34-b754-8c976c351491&utm_source=partner_rko_a_sme" target="_blank" > стать индивидуальным предпринимателем</a> или <a rel="noopener noreferrer" href="https://www.tbank.ru/business/registration-ooo/form?utm_medium=ptr.act&utm_campaign=sme.partners&partnerId=5-3DN0LZH7F&agentId=5-38MDZDGKW&agentSsoId=2fc433e5-1f12-4f34-b754-8c976c351491&utm_source=partner_rko_a_sme" style={{ color: "#007bff" }} target="_blank" >открыть ООО</a></div>
                            </div>
                        }
                    </div>
                    {isPaymentApproveModalVisible && <Modal>
                        <div className={styles.modalCard}>
                            <img className={styles.modalCardCloseIcon} src={CloseIcon} alt="Закрыть" onClick={() => { setPaymentApproveModalVisible(false); setPaymentSettingsVisible(false) }} />
                            <h3 className={styles.modalHeader}>Заполните ваши данные</h3>
                            <div className={styles.profileBlockGroup}>
                                <span>ОГРН</span>
                                <input value={ogrn} onChange={(e) => { setOgrn(e.target.value.trim()) }} type="text" />
                            </div>
                            <div className={styles.button}>
                                {isApprovePending ? <div className={styles.loader} /> : <Button text={'Проверить'} onClick={handleRequestApprove} />}
                            </div>
                        </div>
                    </Modal>}
                    {isPaymentSettingsApproved && isPaymentSettingVisible && <>
                        <div className={styles.paymentInfoFields} >
                            <div className={styles.fieldsColumnWrapper}>
                                <h2 className={styles.fieldsColumnTitle}>Общая информация</h2>
                                <div className={styles.fieldsRow}>
                                    <div className={`${styles.profileBlockGroup} ${styles.profileBlockGroupWrapper}`}>
                                        <span>Полное наименование организации</span>
                                        <input name='fullName' onChange={handlePaymentDataInputChange} value={paymentData.fullName} type="text" />
                                        {formErrors.fullName && <span className={styles.fieldError}>{formErrors.fullName}</span>}
                                    </div>
                                    <div className={`${styles.profileBlockGroup} ${styles.profileBlockGroupWrapper}`}>
                                        <span>Сокращенное наименование организации</span>
                                        <input name='name' onChange={handlePaymentDataInputChange} value={paymentData.name} type="text" />
                                        {formErrors.name && <span className={styles.fieldError}>{formErrors.name}</span>}
                                    </div>
                                    <div className={`${styles.profileBlockGroup} ${styles.profileBlockGroupWrapper}`}>
                                        <span>Название организации на английском</span>
                                        <input name='billingDescriptor' onChange={handlePaymentDataInputChange} value={paymentData.billingDescriptor} type="text" />
                                        {formErrors.billingDescriptor && <span className={styles.fieldError}>{formErrors.billingDescriptor}</span>}
                                    </div>
                                    <div className={`${styles.profileBlockGroup} ${styles.profileBlockGroupWrapper}`}>
                                        <span>ИНН</span>
                                        <input name='inn' onChange={handlePaymentDataInputChange} value={paymentData.inn} type="text" />
                                        {formErrors.inn && <span className={styles.fieldError}>{formErrors.inn}</span>}
                                    </div>
                                    <div className={`${styles.profileBlockGroup} ${styles.profileBlockGroupWrapper}`}>
                                        <span>КПП</span>
                                        <input name='kpp' onChange={handlePaymentDataInputChange} value={paymentData.kpp} type="text" />
                                        {formErrors.kpp && <span className={styles.fieldError}>{formErrors.kpp}</span>}
                                    </div>
                                    <div className={`${styles.profileBlockGroup} ${styles.profileBlockGroupWrapper}`}>
                                        <span>ОГРН</span>
                                        <input name='ogrn' onChange={(e) => setOgrn(e.target.value)} value={ogrn} type="text" />
                                        {formErrors.ogrn && <span className={styles.fieldError}>{formErrors.ogrn}</span>}
                                    </div>
                                    <div className={`${styles.profileBlockGroup} ${styles.profileBlockGroupWrapper}`}>
                                        <span>Email</span>
                                        <input name='email' onChange={handlePaymentDataInputChange} value={paymentData.email} type="text" />
                                        {formErrors.email && <span className={styles.fieldError}>{formErrors.email}</span>}
                                    </div>
                                    <div className={`${styles.profileBlockGroup} ${styles.profileBlockGroupWrapper}`}>
                                        <span>Адрес интернет сайта</span>
                                        <input name='siteUrl' onChange={handlePaymentDataInputChange} value={paymentData.siteUrl} type="text" />
                                        {formErrors.siteUrl && <span className={styles.fieldError}>{formErrors.siteUrl}</span>}
                                    </div>
                                    <div className={`${styles.profileBlockGroup} ${styles.profileBlockGroupWrapper}`}>
                                        <span>Система налогооблажения</span>
                                        <DropdownForForm options={taxationOptions} placeHolder="Система налогооблажения" setChoice={handleTaxationChange} initialChoice={'osn'} />
                                    </div>
                                </div>
                            </div>

                            <div className={styles.fieldsColumnWrapper}>
                                <h2 className={styles.fieldsColumnTitle}>Сведения о руководителе</h2>
                                <div className={styles.fieldsRow}>
                                    <div className={`${styles.profileBlockGroup} ${styles.profileBlockGroupWrapper}`}>
                                        <span>Имя</span>
                                        <input name='ceo.firstName' onChange={handlePaymentDataInputChange} value={paymentData.ceo.firstName} type="text" />
                                        {formErrors.ceo_firstName && <span className={styles.fieldError}>{formErrors.ceo_firstName}</span>}
                                    </div>
                                    <div className={`${styles.profileBlockGroup} ${styles.profileBlockGroupWrapper}`}>
                                        <span>Фамилия</span>
                                        <input name='ceo.lastName' onChange={handlePaymentDataInputChange} value={paymentData.ceo.lastName} type="text" />
                                        {formErrors.ceo_lastName && <span className={styles.fieldError}>{formErrors.ceo_lastName}</span>}
                                    </div>
                                    <div className={`${styles.profileBlockGroup} ${styles.profileBlockGroupWrapper}`}>
                                        <span>Отчество</span>
                                        <input name='ceo.middleName' onChange={handlePaymentDataInputChange} value={paymentData.ceo.middleName} type="text" />
                                        {formErrors.ceo_middleName && <span className={styles.fieldError}>{formErrors.ceo_middleName}</span>}
                                    </div>
                                    <div className={`${styles.profileBlockGroup} ${styles.profileBlockGroupWrapper}`}>
                                        <span>Дата рождения</span>
                                        <InputMask name='ceo.birthDate' mask="99-99-9999" maskChar="_" onChange={handlePaymentDataInputChange} value={paymentData.ceo.birthDate} placeholder="" />
                                        {formErrors.ceo_birthDate && <span className={styles.fieldError}>{formErrors.ceo_birthDate}</span>}
                                    </div>
                                    <div className={`${styles.profileBlockGroup} ${styles.profileBlockGroupWrapper}`}>
                                        <span>Контактный телефон</span>
                                        <InputMask
                                            mask="+7 (999) 999-99-99"
                                            maskChar="_"
                                            placeholder="Телефон руководителя"
                                            name="ceo.phone"
                                            value={paymentData.ceo.phone}
                                            onChange={handlePaymentDataInputChange}
                                        />
                                        {formErrors.ceo_phone && <span className={styles.fieldError}>{formErrors.ceo_phone}</span>}
                                    </div>
                                    <div className={`${styles.profileBlockGroup} ${styles.profileBlockGroupWrapper}`}>
                                        <span>Страна гражданства</span>
                                        <DropdownForForm options={countryCodes} placeHolder="Выберите страну" setChoice={(value) => { updatePaymentState('ceo.country', value); delete formErrors.ceo_country; }} />
                                        {formErrors.ceo_country && <span className={styles.fieldError}>{formErrors.ceo_country}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className={styles.fieldsColumnWrapper}>
                                <h2 className={styles.fieldsColumnTitle}>Контактная информация</h2>
                                <div className={styles.fieldsRow}>
                                    <div className={`${styles.profileBlockGroup} ${styles.profileBlockGroupWrapper}`}>
                                        <span>Тип адреса</span>
                                        <DropdownForForm options={addressTypes} placeHolder="" setChoice={(value) => { updatePaymentState('legal_address.type', value) }} initialChoice={'legal'} />
                                    </div>
                                    <div className={`${styles.profileBlockGroup} ${styles.profileBlockGroupWrapper}`}>
                                        <span>Почтовый индекс</span>
                                        <input name='legal_address.zip' onChange={handlePaymentDataInputChange} value={paymentData.legal_address.zip} type="text" />
                                        {formErrors.legal_address_zip && <span className={styles.fieldError}>{formErrors.legal_address_zip}</span>}
                                    </div>
                                    <div className={`${styles.profileBlockGroup} ${styles.profileBlockGroupWrapper}`}>
                                        <span>Страна</span>
                                        <DropdownForForm options={countryCodes} placeHolder="Выберите страну" setChoice={(value) => { updatePaymentState('legal_address.country', value); delete formErrors.legal_address_country; }} />
                                        {formErrors.legal_address_country && <span className={styles.fieldError}>{formErrors.legal_address_country}</span>}
                                    </div>
                                    <div className={`${styles.profileBlockGroup} ${styles.profileBlockGroupWrapper}`}>
                                        <span>Город или населенный пункт</span>
                                        <input name='legal_address.city' onChange={handlePaymentDataInputChange} value={paymentData.legal_address.city} type="text" />
                                        {formErrors.legal_address_city && <span className={styles.fieldError}>{formErrors.legal_address_city}</span>}
                                    </div>
                                    <div className={`${styles.profileBlockGroup} ${styles.profileBlockGroupWrapper}`}>
                                        <span>Улица, дом</span>
                                        <input name='legal_address.street' onChange={handlePaymentDataInputChange} value={paymentData.legal_address.street} type="text" />
                                        {formErrors.legal_address_street && <span className={styles.fieldError}>{formErrors.legal_address_street}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className={styles.fieldsColumnWrapper}>
                                <h2 className={styles.fieldsColumnTitle}>Информация для платежей</h2>
                                <div className={styles.fieldsRow}>
                                    <div className={`${styles.profileBlockGroup} ${styles.profileBlockGroupWrapper}`}>
                                        <span>Расчётный или казначейский счёт</span>
                                        <input name='bankAccount.account' onChange={handlePaymentDataInputChange} value={paymentData.bankAccount.account} type="text" />
                                        {formErrors.bankAccount_account && <span className={styles.fieldError}>{formErrors.bankAccount_account}</span>}
                                    </div>
                                    <div className={`${styles.profileBlockGroup} ${styles.profileBlockGroupWrapper}`}>
                                        <span>Наименование банка</span>
                                        <input name='bankAccount.bankName' onChange={handlePaymentDataInputChange} value={paymentData.bankAccount.bankName} type="text" />
                                        {formErrors.bankAccount_bankName && <span className={styles.fieldError}>{formErrors.bankAccount_bankName}</span>}
                                    </div>
                                    <div className={`${styles.profileBlockGroup} ${styles.profileBlockGroupWrapper}`}>
                                        <span>БИК</span>
                                        <input name='bankAccount.bik' onChange={handlePaymentDataInputChange} value={paymentData.bankAccount.bik} type="text" />
                                        {formErrors.bankAccount_bik && <span className={styles.fieldError}>{formErrors.bankAccount_bik}</span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.paymentActionsWrapper}>
                            <button className={styles.paymentSubmitButton} type='button' onClick={handlePaymentSubmit}>Сохранить данные</button>
                            {/* {isPaymentDataSent && <div className={styles.paymentSubmitMessage}>Данные были сохранены ожидайте подтверждения администратором и банком.</div>} */}
                        </div>
                    </>}
                </div>

                <div id="subscription" className={styles.tarifsContainer}>
                    <TariffsBlock currentTariff={currentTariff} setCurrentTariff={setCurrentTariff} />
                </div>
                <div className={styles.profileDelContainer}>
                    <div className={styles.profileDelContent}>
                        <h1 className={styles.title}>Удалить профиль</h1>
                        <span className={styles.spanInfo}>Если вы испытываете трудности при использовании сервиса или не нашли необходимые Вам функции, то, прежде чем удалить профиль, свяжитесь с нами - мы попробуем решить проблему!</span>
                        <PopupCustomComponent triggerEl={<button className={styles.cancelBtn}>Удалить профиль</button>} title={"Вы действительно хотите удалить аккаунт? Это действие необратимо!"} handler={handleDeleteAccount} />
                    </div>
                </div>
            </div>

            {isLoading && <Loading />}
        </div >
    )
}

export default SettingsComponent