import axios from "axios";

const baseAPIURL = "https://api-admin.thebotique.ru"

const api = {
    getStoreCategories: async () => {
        try {
            return await axios.get(`${baseAPIURL}/api/v2/store-categories/`, {
                params: {
                    token: localStorage.getItem("_signiture_for_store_data"),
                    store_id: localStorage.getItem("current_store_id")
                },
                headers: { Authorization: "Bearer " + localStorage.getItem("access_token"), },
            }).catch((e) => console.log(e.response));
        } catch (error) {
            throw error;
        }
    },
    createStoreCategory: async (name) => {
        try {
            const response = await axios.post(`${baseAPIURL}/api/v2/create-store-category/`,
                {
                    token: localStorage.getItem("_signiture_for_store_data"),
                    store_id: localStorage.getItem("current_store_id"),
                    name: name
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("access_token"),
                    }
                }
            );
            return response;
        } catch (error) {
            throw error;
        }
    },
    createStoreSubCategory: async (name, categoryID) => {
        try {
            const response = await axios.post(`${baseAPIURL}/api/v2/store-category/${categoryID}/create-store-subcategory/`,
                {
                    token: localStorage.getItem("_signiture_for_store_data"),
                    store_id: localStorage.getItem("current_store_id"),
                    name: name
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("access_token"),
                    }
                }
            );
            return response;
        } catch (error) {
            throw error;
        }
    },
    updateStoreCategory: async (newState, categoryID) => {
        try {
            const response = await axios.put(`${baseAPIURL}/api/v2/update-store-category/${categoryID}/`,
                {
                    token: localStorage.getItem("_signiture_for_store_data"),
                    store_id: localStorage.getItem("current_store_id"),
                    state: newState
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("access_token"),
                    }
                }
            );
            return response;
        } catch (error) {
            throw error;
        }
    },
    updateStoreSubCategory: async (newState, categoryID, subCategoryID) => {
        try {
            const response = await axios.put(`${baseAPIURL}/api/v2/store-category/${categoryID}/store-subcategory/${subCategoryID}/`,
                {
                    token: localStorage.getItem("_signiture_for_store_data"),
                    store_id: localStorage.getItem("current_store_id"),
                    state: newState
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("access_token"),
                    }
                }
            );
            return response;
        } catch (error) {
            throw error;
        }
    },
};

export default api;