import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../api/api";
import infoImg from '../assets/info-icon.svg';
import pinImg from '../assets/pin-icon.svg';
import status from "../assets/status.svg";
import FormatNumbers from "../components/FormatNumbersComponent";
import { error } from "../components/notification";
import styles from "../styles/ProductListComponent_v2.module.css";
import CustomCheckBox from "./CustomCheckBoxComponent";
import ImageComponent from "./ImageCustomComponet";
import PopupCustomComponent from "./PopupCustomComponent";


function ProductItem({ item, handleDelete, setIsInfo, setInfo, handlerForChoosenProduct, menuArr }) {
    const nav = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(item.status);
    const [showStats, setShowStats] = useState(false);
    const [checkBoxFlag, setCheckBoxFlag] = useState(menuArr.indexOf(item.id) === -1 ? false : true);
    const [checkBoxState, setCheckBoxState] = useState(menuArr.indexOf(item.id) === -1 ? false : true);
    const [showTooltip1, setShowTooltip1] = useState(false);
    const [showTooltip2, setShowTooltip2] = useState(false);
    const handlerForCheckBoxState = () => {
        setCheckBoxState((prevState) => !prevState);
        handlerForChoosenProduct(item.id);
    };

    const handleMouseEnter = () => {
        setShowTooltip1(true);
    };

    const handleMouseLeave = () => {
        setShowTooltip1(false);
    };

    const handleMouseEnterD = () => {
        setShowTooltip2(true);
    };

    const handleMouseLeaveD = () => {
        setShowTooltip2(false);
    };

    const handlerEditproducts = () => {
        nav(`/product/${item.id}`);
    }

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handlerForCheckBox = () => {
        setCheckBoxFlag(true);
    };

    const handlerLeaveCheckBox = () => {
        if (!checkBoxState) {
            setCheckBoxFlag(false);
        }
    };


    const selectOption = (option) => {
        const newState = !selectedOption;
        api.updateProductStateV2(item.id, { 'state': newState })
            .then((r) => {
                if (r?.status === 200) {
                    setSelectedOption(prevState => newState);
                    setIsOpen(false);
                } else if (r?.response?.status === 400) {
                    setIsInfo(true); setInfo('Товар закончился и не может быть опубликован.');
                    error('Проверьте остаток товара.');
                }
            })
            .catch((e) => { });
    };
    useEffect(() => {
        const index = menuArr.indexOf(item.id);
        if (index !== -1) {
            setCheckBoxState(true);
            setCheckBoxFlag(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={styles.product} onMouseEnter={handlerForCheckBox} onMouseLeave={handlerLeaveCheckBox}>
            <div className={styles.checkBoxStorage}>
                {checkBoxFlag === true | menuArr.length > 0 ? <CustomCheckBox checked={checkBoxState} onChange={handlerForCheckBoxState}></CustomCheckBox> : null}
            </div>
            <div className={styles.dropdown}>
                <button className={styles.dropdownToggle} onClick={toggleDropdown}>
                    {selectedOption ? 'Продается' : 'Не продается'}
                    <img src={status} alt='' />
                </button>
                {isOpen && (
                    <ul className={styles.dropdownMenu} onMouseLeave={toggleDropdown}>
                        {["Продается", "Не продается"].map((option, index) => (
                            <li key={index} onClick={(e) => {
                                e.stopPropagation();
                                selectOption(option)
                            }}
                            >
                                {option}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
            <div className={styles.productImg}>
                {item.first_photo_url ?
                    <div className={styles.productsImgContainer}>
                        <ImageComponent imagePath={item?.first_photo_url} className={styles.productActualImage} ourWidth={80} />
                        {item?.total_photos === 1 ? null : <span>{`+ ${item?.total_photos - 1} фото`}</span>}
                    </div> :
                    <div className={styles.productsImgContainer}>
                        <div className={styles.productEmptyImg}>
                            <span>нет фото</span>
                        </div>
                        {item?.total_photos === 1 ? null : <span>{`+ ${item?.total_photos} фото`}</span>}
                    </div>
                }
            </div>
            <div className={styles.productName}>
                <span onMouseEnter={() => setShowStats(true)} onMouseLeave={() => setShowStats(false)}>
                    {item.pin && <img src={pinImg} alt="" />}
                    <img src={infoImg} alt="" />
                    {showStats && (
                        <div className={styles.statsNotification}>
                            {item.cartCount === 0 ? (<p>Нет статистики</p>) : (<p>Конверсия**: {item.order_count} / {item.cart_count}</p>)}
                        </div>
                    )}
                </span>
                <span>{item.name}</span>
            </div>

            <div className={styles.productPrice}>
                <FormatNumbers number={item.price + " "} />&#8381;
            </div>
            <div className={styles.productSalePrice}>
                <FormatNumbers number={item.total_price + " "} />&#8381;
            </div>
            <div className={styles.productCategory}>
                {item.category_name}
            </div>
            <div className={styles.productSubCategory}>
                {item.stock === null ? 0:item.stock}
            </div>
            <button className={styles.productCorrect} onClick={handlerEditproducts} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} />
            {showTooltip1 && (
                <div className={styles.editNotification}>
                    Редактировать
                </div>
            )}
            {showTooltip2 && (
                <div className={styles.deleteNotification}>
                    Удалить
                </div>
            )}

            <PopupCustomComponent triggerEl={<button className={styles.productDelete} onMouseEnter={handleMouseEnterD} onMouseLeave={handleMouseLeaveD}>×</button>} title={"Вы действительно хотите удалить товар?"} text={"После удаления восстановить товар не получится"} handler={handleDelete} paramsHandler={item.id} />
        </div>
    )
}



export default ProductItem;