import React, { useState, useEffect } from 'react';
import styles from './TarifsPeriodsComponent.module.css';

const periods = [
  { label: '1 месяц', value: '0', sale: "" },
  { label: '3 месяца', value: '0.1', sale: "10" },
  { label: '6 месяцев', value: '0.2', sale: "20" },
  { label: '12 месяцев', value: '0.3', sale: "30" },
];

const TarifsPeriods = ({ pricing, updatePricing, updatePeriod }) => {
  const [activePeriod, setActivePeriod] = useState(periods[0].label);
  const [originalStandPrice] = useState({ disPrice: pricing.standart.price, price: pricing.standart.old_price });
  const [originalIndPrice] = useState({ disPrice: pricing.individual.price, price: pricing.individual.old_price });
  const handlePeriodChange = (value) => {
    setActivePeriod(value);
    updatePeriod(value);
  };

  const handleForSale = (sale) => {
    if (sale === "3 месяца") {
      return styles.sale10;
    }
    if (sale === "6 месяцев") {
      return styles.sale20;
    }
    if (sale === "12 месяцев") {
      return styles.sale30;
    }
    return ''; // Для 1 месяца нет скидки
  };


  useEffect(() => {
    const selectedPeriod = periods.find(period => period.label === activePeriod);

    let newPricing;

    // Если выбран 1 месяц, устанавливаем обычные цены
    if (activePeriod === '1 месяц') {
      newPricing = {
        standart: {
          old_price: originalStandPrice.price,
          price: originalStandPrice.disPrice // Округляем до ближайших сотен
        },
        individual: {
          old_price: originalIndPrice.price,
          price: originalIndPrice.disPrice // Округляем до ближайших сотен
        }
      };
    } else {
      const discount = parseFloat(selectedPeriod.value); // Скидка в виде числа

      // Устанавливаем новые цены с учетом скидки
      let newStantPrice = originalStandPrice.price * parseInt(activePeriod);
      let newIndiviPrice = originalIndPrice.price * parseInt(activePeriod);
      newPricing = {
        standart: {
          old_price: newStantPrice,
          price: newStantPrice * (1 - discount) // Округляем цену после применения скидки
        },
        individual: {
          old_price: newIndiviPrice,
          price: newIndiviPrice * (1 - discount) // Округляем цену после применения скидки
        }
      };
    }

    updatePricing(newPricing);
  }, [activePeriod]);

  return (
    <div className={styles.periodSelector}>
      {periods.map((period) => (
        <button
          key={period.value}
          className={`${styles.periodButton} ${activePeriod === period.label ? styles.active : ''}`}
          onClick={() => handlePeriodChange(period.label)}
        >
          <span>{period.label}</span>
          <span className={handleForSale(period.label)}>{period.sale !== '' && "-" + period.sale + "%"}</span>
        </button>
      ))}
    </div>
  );
};

export default TarifsPeriods;
