import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage/HomePage';
import NotFound from './pages/NotFoundPage/NotFound';

import BannersPage from './pages/BannersPage/BannersPage';
import EditBannerPage from './pages/BannersPage/EditBannerPage';
import NewBannerPage from './pages/BannersPage/NewBannerPage';
import DesignChoicePage from './pages/DesignChoicePage/DesignChoicePage';
import OrderViewPage from './pages/OrdersPage/OrderViewPage';
import OrdersPage from './pages/OrdersPage/OrdersPage';
import PositionsViewPage from './pages/PositionsViewPage/PositionsViewPage';
import { ProductPage } from "./pages/ProductPage_New/ProductPage";
import ProfilePage from './pages/ProfilePage/ProfilePage';
import CategoriesPage from './pages/CategoriesPage/CategoriesPage';
import StoreManagementPage from './pages/StoreManagementPage/StoreManagementPage';
import StoreViewPage from './pages/StoreViewPage/StoreViewPage';
import SupportPage from './pages/SupportPage/SupportPage';

import PrivateRoute from './PrivateRoute';
import ScrollToTop from './ScrollTopRoute';
import MarketingPage from './pages/MarketingPage/MarketingPage';
import NotificationPage from './pages/NotificationsPage/NorificationsPage';
import ProductsGroupPage from './pages/ProductsGroupPage/ProductsGroupPage';
import StoreSettingsPage from './pages/StoreSettingsPage/StoreSettingsPage';
import EmailConfirmationPage from './pages/authPage/EmailConfirmationPage';
import LoginPage from './pages/authPage/LoginPage';
import PinCodeVerification from './pages/authPage/PinCodeVerification';
import RefCodeServicePage from './pages/authPage/RefCodeServicePage';
import RegistrationPageV2 from './pages/authPage/RegistrationPageV2';
import ResetPage from './pages/authPage/ResetPage';
import YaTokenServicePage from './pages/authPage/YaTokenServicePage';

function AppRouter() {
  return (
    <Router>
      <ScrollToTop>
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/suggest/token" element={<YaTokenServicePage />} />
          <Route path="/reset-password" element={<ResetPage />} />
          <Route path="/register" element={<RegistrationPageV2 />} />
          <Route path="/email-confirmation/:token" element={<EmailConfirmationPage />} />
          <Route path="/email-confirmation/pin-code" element={<PinCodeVerification />} />
          <Route path="/invite/:code" element={<RefCodeServicePage />} />
          <Route exact path='/' element={<PrivateRoute />}>
            <Route path="/" element={<HomePage />} />
            <Route path="/notifications" element={<NotificationPage />}></Route>
            <Route exact path="/stores" element={<StoreManagementPage />} />
            <Route exact path="/store/set-design" element={<DesignChoicePage />} />
            <Route exact path="/store-settings/:storeID" element={<StoreSettingsPage />} />
            <Route exact path="/store/:storeID" element={<StoreViewPage />} />
            <Route exact path="/store/:storeID/set-design" element={<DesignChoicePage />} />
            <Route exact path="/positions" element={<PositionsViewPage />} />
            <Route exact path='/product/:productID' element={<ProductPage />} />
            <Route exact path='/product-groups' element={<ProductsGroupPage />} />
            <Route exact path="/banners" element={<BannersPage />} />
            <Route exact path="/marketing" element={<MarketingPage />} />
            <Route exact path="/create-banner" element={< NewBannerPage />} />
            <Route exact path="/banner/:bannerID" element={<EditBannerPage />} />
            <Route exact path="/orders" element={<OrdersPage />} />
            <Route exact path="/order/:orderID" element={<OrderViewPage />} />
            <Route exact path="/profile" element={<ProfilePage />} />
            <Route exact path="/support" element={<SupportPage />} />
            <Route exact path="/categories" element={<CategoriesPage></CategoriesPage>}/>
          </Route>
        </Routes>
      </ScrollToTop>

    </Router>
  );
}

export default AppRouter;


