import { transform } from 'lodash';
import React from 'react';

// Компонент для отображения картинок в зависимости от даты
const DynamicImageComponent = ({ normalImage, newYearImg, className,extraStylesForNewYearImg }) => {
    // Получаем текущую дату
    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = currentDate.getMonth(); // Месяцы начинаются с 0 (январь = 0)

    // Условие для выбора картинки
    let imageSrc = normalImage; // По умолчанию используем normalImage
    let extraStyle;

    if ((month === 11 && day >= 15) || (month === 0 && day <=15)) {
        // С 15 декабря по 14 января
        imageSrc = newYearImg;
        extraStyle = extraStylesForNewYearImg === undefined ? {transform: "scale(1.4)"}:extraStylesForNewYearImg;
    } else {
        imageSrc = normalImage; 
    }

    return (
            <img src={imageSrc} style={extraStyle} alt="Dynamic based on date" className={className}/>
    );
};

export default DynamicImageComponent;