import React, { useEffect, useState } from "react";
import CategoriesSubcategoriesComponent from "../../components/CategoriesSubcategoriesComponent";
import Footer from "../../components/FooterComponent_v2";
import Header from "../../components/HeaderComponent_v2";
import api from "./api/api";

export default function CategoriesPage() {

    const [categories, setCategories] = useState([]);

    const handleCategoryCheckBoxChange = (categoryId) => {
        const currentCategory = categories.filter(category => category.id === categoryId)[0];
        api.updateStoreCategory(!currentCategory.state, categoryId).then(() => {
            setCategories((prevCategories) =>
                prevCategories.map((category) =>
                    category.id === categoryId
                        ? {
                            ...category,
                            state: !category.state,
                            subcategories: category.subcategories.map((subcategory) => ({
                                ...subcategory,
                                state: !category.state,
                            })),
                        }
                        : category
                )
            );
        });
    };

    const handleSubCategoryCheckBoxChange = (categoryId, subCategoryId) => {
        const currentCategory = categories.filter(category => category.id === categoryId)[0];
        const currentSubCategory = currentCategory.subcategories.filter(subcategory => subcategory.id === subCategoryId)[0];
        api.updateStoreSubCategory(!currentSubCategory.state, categoryId, subCategoryId).then(() => {
            setCategories((prevCategories) =>
                prevCategories.map((category) => ({
                    ...category,
                    subcategories: category.subcategories.map((subcategory) =>
                        subcategory.id === subCategoryId
                            ? { ...subcategory, state: !subcategory.state }
                            : subcategory
                    ),
                }))
            );
        })
    };

    const createCategory = async (name) => {
        return await api.createStoreCategory(name);
    };

    const createSubCategory = async (name, categoryID) => {
        return await api.createStoreSubCategory(name, categoryID);
    };

    useEffect(() => {
        api.getStoreCategories().then((r) => {
            if (r.data) {
                setCategories(r.data);
            }
        });
    }, []);

    return (
        <>
            <Header title={"Управление категориями"} />
            <CategoriesSubcategoriesComponent categories={categories}
                setCategories={setCategories}
                handleCategoryCheckBoxChange={handleCategoryCheckBoxChange}
                handleSubCategoryCheckBoxChange={handleSubCategoryCheckBoxChange}
                createCategoryRequest={createCategory}
                createSubCategoryRequest={createSubCategory} />
            <Footer />
        </>

    )
}