import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../api/api";

import box1 from "../../../assets/template_1_cat.png";
import box2 from "../../../assets/template_1_prod.png";
import box3 from "../../../assets/template_1_order.png";

import list1 from "../../../assets/template_2_cat.png";
import list2 from "../../../assets/template_2_cart.png";
import list3 from "../../../assets/template_2_order.png";

import base1 from "../../../assets/store_templates/white/white_new_1.png";
import base2 from "../../../assets/store_templates/white/white_new_2.png";
import base3 from "../../../assets/store_templates/white/white_new_3.png";

import blue1 from "../../../assets/store_templates/blue/blue1.png";
import blue2 from "../../../assets/store_templates/blue/blue2.png";
import blue3 from "../../../assets/store_templates/blue/blue3.png";

import dark1 from "../../../assets/store_templates/dark/photo_2024-12-13 14.11.50.jpeg";
import dark2 from "../../../assets/store_templates/dark/photo_2024-12-13 14.11.40.jpeg";
import dark3 from "../../../assets/store_templates/dark/photo_2024-12-13 14.11.46.jpeg";

import styles from "./DesignComponent_v2.module.css";
import DesignItem from "./DesignItemComponent_v2";

function Design() {
    const { storeID } = useParams();
    const nav = useNavigate();

    // let initialData = [{ name: "base", images: [base1, base2, base3], active: false }, { name: "clothe", images: [clothe1, clothe2, clothe3], active: false }, { name: "balet", images: [balet1, balet2, balet3], active: false }, { name: "flowers", images: [flow1, flow2, flow3], active: false }, { name: "animals", images: [ani1, ani2, ani3], active: false }];
    let initialData = [{ name: "base", images: [base1, base2, base3], active: false }, { name: "box", images: [box1, box2, box3], active: false }, { name: "list", images: [list1, list2, list3], active: false }, { name: "blue", images: [blue1, blue2, blue3], active: false }, { name: "dark", images: [dark1, dark2, dark3], active: false }];
    const [data, setData] = useState(initialData);

    const [btnStatus, setBtnStatus] = useState(styles.activeBtn);

    const handlerForDesign = () => {
        setBtnStatus(styles.activeBtn);
    };

    const handleNext = () => {
        if (storeID === undefined) {
            nav('/store/new');
        } else {
            api.updateStoreTemplateCode(storeID, { template_code: localStorage.getItem('template_code') })
                .then((r) => {
                    nav(`/store/${storeID}`);
                })
        }
    };

    useEffect(() => {
        if (storeID === undefined) {
            localStorage.setItem('template_code', 0);

            setData(prevItems => {
                const updatedItems = [...prevItems];

                // Проверка, есть ли элементы в массиве
                if (updatedItems.length > 0) {
                    updatedItems[0] = { ...updatedItems[0], active: true };
                }

                return updatedItems;
            });
        } else {
            const template_code = localStorage.getItem('template_code');

            setData(prevItems => {
                const updatedItems = [...prevItems];

                // Проверка, существует ли индекс перед обновлением
                const indexToUpdate = Number(template_code);
                if (indexToUpdate >= 0 && indexToUpdate < updatedItems.length) {
                    updatedItems[indexToUpdate] = { ...updatedItems[indexToUpdate], active: true };
                }

                return updatedItems;
            });
        }
    }, [storeID]);

    useEffect(() => {
        document.body.classList.add(styles.bodyRuleDisappear);

        return () => {
            document.body.classList.remove(styles.bodyRuleDisappear);
        };
    }, []);
    return (
        <div className={styles.container}>
            <h1 className={styles.title}>Оформление магазина</h1>
            <span className={styles.text}>Выберите любой понравившийся вам шаблон оформления магазина <span>исходя из вашей специфики работы</span></span>
            <div className={styles.designContainer}>
                <ul className={styles.designContainerMenu}>
                    <li>Продажа товаров</li>
                    {/* <li>Предоставление услуг</li>
                    <li>Бронирование билетов</li> */}
                </ul>
                <div className={styles.design}>
                    {data.map((item, index) => <DesignItem images={item.images} handlerTochangeBtn={handlerForDesign} isActive={item.active} index={index} />)}
                </div>
            </div>
            <button className={btnStatus} onClick={handleNext}>Далее</button>
            <div className={styles.info}>
                <p>
                    Хотите индивидуальный дизайн? Можем предоставить вам услугу по разработке дизайна, разработанного специально под ваш магазин. Оставьте заявку и мы расскажем подробнее
                </p>
                <button onClick={() => { window.location = '/support' }}>Узнать подробнее</button>
            </div>
        </div>
    )
}
export default Design;