import React, { useEffect, useState } from "react";

function ImageComponent({  imagePath, className, ourWidth, height }) {

  const [pictureParams] = useState({
    width: ourWidth,
    height: height ?? null,
    margin: 0,
    left: 0
  });


  useEffect(() => {
    const getImageAspectRatio = async () => {
      try {
        const img = new Image();
        img.src = imagePath;
        await img.decode();

      } catch (error) {
        console.error('Ошибка загрузки изображения:', error);
      }
    };
    getImageAspectRatio();
  }, [imagePath]);




  return (
    // eslint-disable-next-line jsx-a11y/alt-text
    <img src={imagePath} style={{
      maxWidth: pictureParams.width,
      maxHeight: pictureParams.height,
      paddingTop: pictureParams.margin / 2,
      paddingBottom: pictureParams.margin / 2,
      paddingLeft: pictureParams.left,
    }} className={className} />
  );
}

export default ImageComponent