import { format } from 'date-fns';
import React, { useEffect, useState } from "react";
import api from '../../../api/api';
import gal from "../../../assets/check 1.png";
import ErrorMessage from "../../../components/ErrorMessage";
import PopupCustomComponent from '../../../components/PopupCustomComponent';
import TariffModal from './TariffModal';
import styles from "./TariffsBlock.module.css";
import TarifsPeriods from './TarifsPeriodsComponent';


export default function TariffsBlock({ currentTariff, setCurrentTariff }) {

    const [pricing, setPricing] = useState({ standart: { old_price: 0, price: 0 }, individual: { old_price: 0, price: 0 } });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState({ period: 1, type: 1, price: 1999, old_price: 1999, name: 'Стандарт' });

    const [isError, setIsError] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [activePeriod, setActivePeriod] = useState("1 месяц");

    const [standartText, setStandartText] = useState('Выбрать');
    const [individText, setIndividText] = useState('Выбрать');
    const [originPrices, setOriginPrices] = useState();

    useEffect(() => {
        api.getPricing().then(r => { setPricing(r.data); setOriginPrices(r.data) });
    }, []);

    useEffect(() => {
        if (currentTariff.status === false || new Date(currentTariff.renew_date) < new Date()) {
            if (currentTariff.type === 1) {
                setStandartText('Возобновить');
            } else if (currentTariff.type === 2) {
                setIndividText('Возобновить');
            }
        } else {
            if (currentTariff.type === 1) {
                setStandartText('Активный тариф');
            } else if (currentTariff.type === 2) {
                setIndividText('Активный тариф');
            }
        }

        if (currentTariff.type > 1 && currentTariff.status && new Date(currentTariff.renew_date) >= new Date()) {
            setStandartText('Недоступен');
        }
    }, [currentTariff]);

    useEffect(() => {console.log(activePeriod)}, [activePeriod]);

    const handleStandartChoice = () => {
        if (currentTariff.type < 2 && !currentTariff.status || new Date(currentTariff.renew_date) < new Date()) {
            setModalContent(prev => ({ ...prev, type: 1, price: pricing.standart.price / 100, old_price: pricing.standart.old_price / 100, name: 'Стандарт', period: parseInt(activePeriod), originPrice: originPrices }));
            setIsModalOpen(true);
        } else if (currentTariff.type === 2 && !currentTariff.status || new Date(currentTariff.renew_date) < new Date()) {
            setModalContent(prev => ({ ...prev, type: 1, price: pricing.standart.price / 100, old_price: pricing.standart.old_price / 100, name: 'Стандарт', period: parseInt(activePeriod), originPrice: originPrices }));
            setIsModalOpen(true);
        }
    };

    const handleIndividualChoice = () => {
        if (currentTariff.type < 2 || new Date(currentTariff.renew_date) < new Date()) {
            setModalContent(prev => ({ ...prev, type: 2, price: pricing.individual.price / 100, old_price: pricing.individual.old_price / 100, name: 'Индивидуальный', period: parseInt(activePeriod), originPrice: originPrices }));
            setIsModalOpen(true);
        } else if (currentTariff.type === 2 & !currentTariff.status || new Date(currentTariff.renew_date) < new Date()) {
            setModalContent(prev => ({ ...prev, type: 2, price: pricing.individual.price / 100, old_price: pricing.individual.old_price / 100, name: 'Индивидуальный', period: parseInt(activePeriod), originPrice: originPrices }));
            setIsModalOpen(true);
        }
    };

    const cancelSubscription = () => {
        if (currentTariff.type > 0 & currentTariff.status === true) {
            api.cancelTariff().then((r) => {
                setIsError(true);
                setErrorText('Подписка отменена - тариф будет действовать до окончания оплаченного периода.');
                setCurrentTariff(prev => ({
                    ...prev,
                    status: false
                }))
            });
        }
    };
    const trafiNameHandler = (type) => {
        if (type === 1) {
            return "Стандарт"
        }
        if (type === 2) {
            return "Индивидуальный"
        }
    };

    const tarifGetPriceText = (type) => {
        if (type === 1) {
            return "1999"
        }
        if (type === 2) {
            return pricing.individual.price / 100
        }
    };

    const formatNumber = (number) => {
        // Преобразуем число в строку, чтобы применить регулярное выражение
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    };

    const getEndWordPeriod = (number) => {
        if (number === "3") {
            return "3 месяца"
        }
        if (number === "6") {
            return "6 месяцев"
        }
        if (number === "12") {
            return "12 месяцев"
        }
    };


    return (
        <>
            <h1 className={styles.title}>Тарифные планы</h1>
            {
                currentTariff.type > 0 && currentTariff.status && new Date(currentTariff.renew_date) >= new Date() ? (
                    <>
                        <span className={styles.cancel}>
                            {`Ваш тариф: ${trafiNameHandler(currentTariff.type)} - ${tarifGetPriceText(currentTariff.type)} ${currentTariff.period === '1' ? "руб. " + "за " + getEndWordPeriod(currentTariff) : "руб. / месяц"}. `}
                        </span>
                        <span className={styles.cancel}>{`Следующая дата списания: ${format(new Date(currentTariff.renew_date), 'dd.MM.yyyy')}. `}Также вы можете
                            <PopupCustomComponent triggerEl={<span className={styles.cancelBtn}>отменить подписку</span>} title={"Вы действительно хотите отменить подписку?"} text={"Действие тарифа не прекращается до окончания оплаченного периода."} handler={cancelSubscription} />.
                        </span >
                    </>
                ) : <>
                    {pricing.standart.price !== 0 && <TarifsPeriods pricing={pricing} updatePricing={setPricing} updatePeriod={setActivePeriod}></TarifsPeriods>}
                    <div className={styles.tariffContainer}>
                        <div className={styles.tarif}>

                            <h3 className={styles.tarifTitle}>Пробный</h3>

                            <div className={styles.tarifAdvantages}>
                                <div className={styles.advantage}>
                                    <img src={gal} alt="" />
                                    <span> 1 Telegram-магазин</span>
                                </div>
                                <div className={styles.advantage}>
                                    <img src={gal} alt="" />
                                    <span> Добавление 4 товаров</span>
                                </div>
                                <div className={styles.advantage}>
                                    <img src={gal} alt="" />
                                    <span> Простой дизайн магазина</span>
                                </div>
                                <div className={styles.advantage}>
                                    <img src={gal} alt="" />
                                    <span> Ограничение на получение 1 заказа</span>
                                </div>
                                <div className={styles.advantage}>
                                    <img src={gal} alt="" />
                                    <span> Базовая техподдержка</span>
                                </div>
                            </div>

                            <span className={styles.saleText}>Бесплатно</span>

                            <button className={currentTariff.type === 0 ? `${styles.freeButton} ${styles.activeTarif}` : styles.freeButton}> {currentTariff.type === 0 ? "Активный тариф" : "Недоступен"}</button>

                        </div>

                        <div className={styles.tarif}>
                            <h3 className={styles.tarifTitle}>Стандарт</h3>
                            <div className={styles.tarifAdvantages}>
                                <div className={styles.advantage}>
                                    <img src={gal} alt="" />
                                    <span> 1 Telegram-магазин</span>
                                </div>
                                <div className={styles.advantage}>
                                    <img src={gal} alt="" />
                                    <span> Добавление 200 товаров</span>
                                </div>
                                <div className={styles.advantage}>
                                    <img src={gal} alt="" />
                                    <span> 3 дизайна магазинов на выбор</span>
                                </div>
                                <div className={styles.advantage}>
                                    <img src={gal} alt="" />
                                    <span> <b>Неограниченное количество заказов</b></span>
                                </div>
                                <div className={styles.advantage}>
                                    <img src={gal} alt="" />
                                    <span> Базовая техподдержка</span>
                                </div>
                            </div>
                            {pricing.standart.old_price !== pricing.standart.price && < span className={styles.oldSalePrice}>{formatNumber(pricing.standart.old_price / 100)} {activePeriod !== null && activePeriod !== "1 месяц" ? "руб. " + "за " + activePeriod : "руб. / месяц"}</span>}
                            <span className={styles.saleText}>{formatNumber(pricing.standart.price / 100)} {activePeriod !== null && activePeriod !== "1 месяц" ? "руб. " + "за " + activePeriod : "руб. / месяц"}</span>

                            <button className={currentTariff.type === 1 ? `${styles.freeButton} ${styles.activeTarif}` : styles.popupBtnBack} onClick={handleStandartChoice}>{standartText}</button>

                        </div>

                        <div className={styles.tarif}>
                            <h3 className={styles.tarifTitle}>Индивидуальный</h3>
                            <div className={styles.tarifAdvantages}>
                                <div className={styles.advantage}>
                                    <img src={gal} alt="" />
                                    <span> Индивидуальный дизайн магазина или выбор готового</span>
                                </div>
                                <div className={styles.advantage}>
                                    <img src={gal} alt="" />
                                    <span> Персональный менеджер поддержки с полноценным сопровждением</span>
                                </div>
                                <div className={styles.advantage}>
                                    <img src={gal} alt="" />
                                    <span> Доступ к списку посетителей и аналитике магазина</span>
                                </div>
                                <div className={styles.advantage}>
                                    <img src={gal} alt="" />
                                    <span> Таргетированные рекламные рассылки для покупателей</span>
                                </div>
                                {/* <div className={styles.advantage}>
                            <img src={gal} alt="" />
                            <span> <b>Участие в программе «маркетплейс Botique»</b></span>
                        </div> */}
                            </div>

                            {pricing.individual.old_price !== pricing.individual.price && < span className={styles.oldSalePrice}>{formatNumber(pricing.individual.old_price / 100)} {activePeriod !== null && activePeriod !== "1 месяц" ? "руб. " + "за " + activePeriod : "руб. / месяц"}</span>}
                            <span className={styles.saleText}>{formatNumber(pricing.individual.price / 100)} {activePeriod !== null && activePeriod !== "1 месяц" ? "руб. " + "за " + activePeriod : "руб. / месяц"}</span>

                            <button className={currentTariff.type === 2 ? `${styles.freeButton} ${styles.activeTarif}` : styles.popupBtnBack} onClick={handleIndividualChoice}>{individText}</button>
                        </div>
                    </div >
                </>

            }
            {isError ? (<ErrorMessage message={errorText} />) : (null)}

            <TariffModal isOpen={isModalOpen} content={modalContent} onClose={() => { setIsModalOpen(false); }} />
        </>
    );
}