import { useState } from 'react';
import { removeBaseComboPhoto, removePhotoFromCombo } from '../../../handlers/handlers';
import styles from './PhotoList.module.css';
import { PhotoListItem } from './PhotoListItem/PhotoListItem';

export const PhotoList = ({ width, height, photos, setPhotos, productData, setProductData, comboIndex }) => {

    const deletePhotoFromList = (idx) => {
        comboIndex !== undefined ? removePhotoFromCombo(comboIndex, idx, productData, setProductData) : removeBaseComboPhoto(idx, productData, setProductData);
    };
    const [isLoading,setIsLoading] = useState(false);
    const setChoosenPhotos = (choosenPhotoList) => {
        setPhotos([...photos, ...choosenPhotoList]);
    }

    return (
        <div className={styles.wrapper}>
            {isLoading && <div className={styles.loading}><span>Идет загрузка...</span></div>}
            {photos.map((item, index) => {
                return (
                    <PhotoListItem
                        setLoading={setIsLoading}
                        key={index}
                        url={item.url}
                        width={index > 0 ? 80 : 280}
                        height={index > 0 ? 70 : 232}
                        deleteCallback={deletePhotoFromList}
                        index={index}
                        chooseFilesCallback={setChoosenPhotos}
                    />)
            })}
            <PhotoListItem
                key={'empty_photo_item'}
                url={null}
                setLoading={setIsLoading}
                width={photos.length > 0 ? 80 : 280}
                height={photos.length > 0 ? 70 : 232}
                deleteCallback={deletePhotoFromList}
                index={photos.length > 0 ? photos.length : 0}
                chooseFilesCallback={setChoosenPhotos}
            />
        </div>
    );
}